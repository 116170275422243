import React from "react";
import './Spinner.css'

function Spinner() {
  return (
    <div class="overlay">
      <div class="spinner"></div>
    </div>
  );
}

export default Spinner;
